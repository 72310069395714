import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { changePage } from 'store/actions';
import 'components/Pagine/GiftCollection/ElencoGifts/BoxGift/BoxGift.css';

class BoxGift extends Component {

  componentDidMount() {
    //////console.log(process.env.PUBLIC_URL);
  }

  componentDidUpdate() {
    //////console.log(process.env.PUBLIC_URL);
  }

  onRenderGift = () => {
    // recupero dati gift

    let giftObjD = this.props.sezione.datiPagina.gifts;

    let giftObj = Object.keys(giftObjD)
              .filter(key => giftObjD[key]["ID"] === this.props.gift)
              .reduce((obj, key) => {
                return giftObjD[key];
              }, {});
    if (giftObj) {
      let idGift = giftObj.ID;
      let immagineGift = giftObj.meta.ImmagineGift;
      let nomeGift = giftObj.Gift;
      let urlGift = giftObj.UrlSEO;
      let giftSelected = '';
      let coloreCantina = '';

      let cantina = '';
      if (this.props.sezione.datiPagina.cantina) {
        cantina = this.props.sezione.datiPagina.cantina.replace(/\s/g,'');
        cantina = cantina.replace('&','');
      }

      if (this.props.sezione.datiPagina.colore) {
        coloreCantina = this.props.sezione.datiPagina.colore;
      }
      
      if (this.props.sezione.datiPagina.giftSelezionato) {
        if (this.props.sezione.datiPagina.giftSelezionato.ID === idGift) {
          giftSelected = 'selected';
        }
      }
      return <div style={{...this.props.style}} className={`d-flex flex-column align-items-center justify-content-center ${giftSelected} ${cantina}`} onClick={() => this.onClickGift(`${urlGift}`)}>
        <Image
          src={`${process.env.PUBLIC_URL}/images/gift/${immagineGift}.png`} 
          alt={nomeGift}
          className="immagineGiftElenco"
        />
        <div className="lineaRossa" style={{ background: `linear-gradient(to right, transparent 50%, ${coloreCantina} 50%, ${coloreCantina} 51%, transparent 51% )` }}></div>
        <h3 className="fsize19 fsemib p-3">{nomeGift}</h3>
      </div>;
    }
  }

  onClickGift = urlGift => {
    let sezioneCorrente = this.props.sezione.sezionePagina;
    let sezionePrecedente = this.props.sezione.sezionePrecedente;

    let filtroCantina = '';

    if(this.props.filtri) {
      if(this.props.filtri.cantina !== undefined) {
        //filtroCantina = this.props.filtri.cantina.toLocaleLowerCase();
      }
    }

    //console.log(urlGift);

    let giftObjD = null;
    if (filtroCantina) {
      giftObjD = this.props.giftcollection;
    } else {
      giftObjD = this.props.sezione.datiPagina.gifts;
    }
    
    //console.log(giftObjD);
    if (giftObjD) {
      let giftObj = Object.keys(giftObjD)
            .filter(key => giftObjD[key].UrlSEO === urlGift)
            .reduce((obj, key) => {
              return giftObjD[key];
            }, {});
            //console.log(giftObj);
      if (giftObj) {
        
        // url cantina + url gift
        let url = '';
        if (filtroCantina) {
          url = giftObj.UrlSEO;
        } else {
          url = `giftcollection/${this.props.sezione.datiPagina.url}/${giftObj.UrlSEO}`;
        }
        //console.log('url gift: '+url);
        // preparo oggetto per dettaglioPagina gift selezionato
        let cantinaObj = '';
        if (filtroCantina) {
          let objCantine = this.props.cantine;
          let cantinaObjSearch = Object.keys(objCantine)
          .filter(key => objCantine[key].cantina.toLocaleLowerCase() === filtroCantina)
          .reduce((obj, key) => {
            return objCantine[key];
          }, {});
          cantinaObj = cantinaObjSearch;
        } else {
          cantinaObj = this.props.sezione.datiPagina;
        }
        
        let giftSelezionato = {...cantinaObj, giftSelezionato: giftObj}
        /* ////console.log('aaaaaa');
        ////console.log(giftObj);
        ////console.log(giftSelezionato); */
        this.props.changePage(url, sezioneCorrente, sezionePrecedente, giftSelezionato); // passo a pagina dettaglio Gift
      }
    }
  }

  render() {
    let boxGift = this.props.sezione.datiPagina.gifts ? this.onRenderGift() : '';

    return boxGift;
  }
}

const mapStateToProps = state => {
  return {
    sezione: state.sezione,
    tipologie: state.catalogo.tipologie,
    cantine: state.catalogo.cantine,
    filtri: state.filtri.selezionati,
    giftcollection: state.catalogo.giftcollection 
  }
}

export default connect(mapStateToProps, { changePage })(BoxGift);