import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchGiftCollection, fetchStatic, changePage } from 'store/actions';
import Slideshow from 'components/UI/Slideshow/Slideshow';
import ElencoGifts from 'components/Pagine/GiftCollection/ElencoGifts/ElencoGifts';
import 'components/Pagine/GiftCollection/GiftCollection.css';

class GiftCollection extends Component {
  state = {
    pageLoaded: false
  }

  componentDidMount() {
    ////console.log('[GiftCollection] componentDidMount');
    ////console.log(this.props.router.action);

    if(this.props.giftcollection) {
      ////console.log('[GiftCollection] componentDidMount:: aggiorno cambio pagina');
      let currentPath = this.props.location.pathname.split('/');
      ////console.log(currentPath);
      let giftCollectionObj = Object.keys(this.props.giftcollection)
              .filter(key => this.props.giftcollection[key]["url"] === currentPath[2])
              .reduce((obj, key) => {
                return this.props.giftcollection[key];
              }, {});

        if (giftCollectionObj.url) {
          this.props.changePage(`giftcollection/${giftCollectionObj.url}`, 'GiftCollection', '', giftCollectionObj); // vado alla pagina della giftcollection
        }
    }
  }

  componentDidUpdate() {
    let sezioneCorrente = this.props.sezione.sezionePagina;
    if (!sezioneCorrente) sezioneCorrente = 'GiftCollection';
    let sezionePrecedente = this.props.sezione.sezionePrecedente;
    if (!sezionePrecedente) sezionePrecedente = 'GiftCollection';


    ////console.log('[GiftCollection] componentDidUpdate');
    ////console.log(this.props.router.action);

    if(!this.props.sezione.nomePagina && this.props.cantine) {
      ////console.log('[Cantina] componentDidUpdate:: aggiorno cambio pagina');
      let currentPath = this.props.location.pathname.split('/');
      ////console.log(currentPath);
      let giftCollectionObj = Object.keys(this.props.giftcollection)
              .filter(key => this.props.giftcollection[key]["url"] === currentPath[1])
              .reduce((obj, key) => {
                return this.props.giftcollection[key];
              }, {});

        if (giftCollectionObj.url) {
          this.props.changePage(`giftcollection/${giftCollectionObj.url}`, sezioneCorrente, sezionePrecedente, giftCollectionObj); // vado alla pagina della giftcollection
        }
    } else if(this.props.router.action === 'POP' && this.props.cantine) {
      if (`/${this.props.sezione.nomePagina}` !== this.props.router.location.pathname) {
        ////console.log('[Cantina] aggiorno pagina cantina');
        let currentPath = this.props.location.pathname.split('/');
        let giftCollectionObj = Object.keys(this.props.giftcollection)
              .filter(key => this.props.giftcollection[key]["url"] === currentPath[1])
              .reduce((obj, key) => {
                return this.props.giftcollection[key];
              }, {});

        if (giftCollectionObj.url) {
          this.props.changePage(`giftcollection/${giftCollectionObj.url}`, sezioneCorrente, sezionePrecedente, giftCollectionObj); // vado alla pagina della giftcollection
        }
      }
    }
  }

  render() {
    let paginaCorrente = this.props.location.pathname.split('/');
    
    return (
      <div>
        <Slideshow sezioneSlide='cantina' paginaSlide={paginaCorrente[2]} />
        <Container>
          <Row noGutters>
            <Col sm={12} className="elencoGift">
              <ElencoGifts />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    router: state.router,
    sezione: state.sezione,
    cantine: state.catalogo.cantine,
    tipologie: state.catalogo.tipologie,
    etichette: state.catalogo.etichette,
    statico: state.statico,
    giftcollection: state.catalogo.giftcollection
  }
}

export default connect(mapStateToProps, { fetchGiftCollection, fetchStatic, changePage })(GiftCollection);