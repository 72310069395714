import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchCantine, fetchStatic, fetchTipologie, fetchEtichette, changePage } from 'store/actions';
import Slideshow from 'components/UI/Slideshow/Slideshow';
import ElencoGifts from 'components/Pagine/GiftCollection/ElencoGifts/ElencoGifts';
import 'components/Pagine/GiftCollection/ElencoGifts/ElencoGifts.css';

class DettaglioGift extends Component {

  componentDidMount() {
    ////console.log('[DettaglioGift] componentDidMount');
    ////console.log(this.props.router.action);

    if(this.props.giftcollection) {
      ////console.log('[DettaglioGift] componentDidMount:: aggiorno pagina gift');
      let currentPath = this.props.location.pathname.split('/');
      let giftCollectionObj = Object.keys(this.props.giftcollection)
            .filter(key => this.props.giftcollection[key]["url"] === currentPath[2])
            .reduce((obj, key) => {
              return this.props.giftcollection[key];
            }, {});
      let giftObj = Object.keys(giftCollectionObj.gifts)
          .filter(key => giftCollectionObj.gifts[key].UrlSEO === currentPath[3])
          .reduce((obj, key) => {
            return giftCollectionObj.gifts[key];
          }, {}); 
      if (giftObj) {
        // url cantina + url gift
        let url = `giftcollection/${giftCollectionObj.url}/${giftObj.UrlSEO}`;
        // preparo oggetto per dettaglioPagina gift selezionato
        let giftSelezionato = {...giftCollectionObj, giftSelezionato: giftObj}
        this.props.changePage(url, 'GiftCollection', '', giftSelezionato); // passo a pagina dettaglio Gift
      }
    }
  }

  componentDidUpdate() {

    let sezioneCorrente = this.props.sezione.sezionePagina;
    if (!sezioneCorrente) sezioneCorrente = 'GiftCollection';
    let sezionePrecedente = this.props.sezione.sezionePrecedente;
    if (!sezionePrecedente) sezionePrecedente = 'GiftCollection';

    if(!this.props.sezione.nomePagina && this.props.giftcollection) {
      ////console.log('[DettaglioGift] aggiorno pagina gift PUSH');
      let currentPath = this.props.location.pathname.split('/');
      let giftCollectionObj = Object.keys(this.props.giftcollection)
            .filter(key => this.props.giftcollection[key]["url"] === currentPath[2])
            .reduce((obj, key) => {
              return this.props.cantine[key];
            }, {});
      let giftObj = Object.keys(giftCollectionObj.gifts)
            .filter(key => giftCollectionObj.gifts[key].UrlSEO === currentPath[3])
            .reduce((obj, key) => {
              return giftCollectionObj.gifts[key];
            }, {});
      if (giftObj) {
        // url cantina + url gift
        let url = `giftcollection/${giftCollectionObj.url}/${giftObj.UrlSEO}`;
        // preparo oggetto per dettaglioPagina gift selezionato
        let giftSelezionato = {...giftCollectionObj, giftSelezionato: giftObj}
        this.props.changePage(url, sezioneCorrente, sezionePrecedente, giftSelezionato); // passo a pagina dettaglio Gift
      }
    } else if(this.props.router.action === 'POP' && this.props.cantine) {
      if (`/${this.props.sezione.nomePagina}` !== this.props.router.location.pathname) {
        ////console.log('[DettaglioGift] aggiorno pagina gift POP');
        let currentPath = this.props.router.location.pathname.split('/');
        let giftCollectionObj = Object.keys(this.props.giftcollection)
              .filter(key => this.props.giftcollection[key]["url"] === currentPath[2])
              .reduce((obj, key) => {
                return this.props.giftcollection[key];
              }, {});
        let giftObj = Object.keys(giftCollectionObj.gifts)
              .filter(key => giftCollectionObj.gifts[key].UrlSEO === currentPath[3])
              .reduce((obj, key) => {
                return giftObj.gifts[key];
              }, {});
        if (giftObj) {
          // url cantina + url gift
          let url = this.props.router.location.pathname.substring(1);
          // preparo oggetto per dettaglioPagina gift selezionato
          let giftSelezionato = {...giftCollectionObj, giftSelezionato: giftObj}
          this.props.changePage(url, sezioneCorrente, sezionePrecedente, giftSelezionato); // passo a pagina dettaglio Gift
        }
      }
    }
  }

  render() {

    const Aux = props => props.children;

    let paginaCorrente = this.props.location.pathname.split('/');

    return (
      <Aux>
        <Slideshow sezioneSlide='dettaglioGift' paginaSlide={paginaCorrente[3]} />
        <Container>
          <Row noGutters>
            <Col sm={12} className="elencoGift pt-3">
              <ElencoGifts />
            </Col>
          </Row>
        </Container>
      </Aux>
    )
  }

}

const mapStateToProps = state => {
  return {
    router: state.router,
    sezione: state.sezione,
    statico: state.statico,
    cantine: state.catalogo.cantine,
    tipologie: state.catalogo.tipologie,
    etichette: state.catalogo.etichette,
    filtri: state.filtri.selezionati,
    giftcollection: state.catalogo.giftcollection
  }
}

export default connect(mapStateToProps, { fetchCantine, fetchStatic, fetchTipologie, fetchEtichette, changePage })(DettaglioGift);