import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changePage } from 'store/actions';
import 'components/Pagine/Blog/BlogFilters/BlogFilters.css';

class BlogFilters extends Component {

    state = {
        filtroCliccato: false,
    }

    onCategorieBlogRender = () => {
        let Aux = props => props.children;
        if (this.props.blogs.categorie) {
            let categorieBlog = this.props.blogs.categorie.map(categoria => {

                // filtro selezionato?
                let filtroSelected = '';
                if (this.props.sezione.datiPagina.categoriaSelezionata) {
                    if(categoria.categoria_it === this.props.sezione.datiPagina.categoriaSelezionata) 
                    {
                        filtroSelected = 'selected';
                    }
                }
                

                return (
                <Aux key={categoria.id}>
                    <div style={{ backgroundColor : `${categoria.colore}`}}>
                        <Button 
                            variant="secondary" 
                            size="md" 
                            block 
                            className={`text-left ${filtroSelected}`}
                            onClick={() => this.onCategorieBlogClick(categoria.categoria_it, categoria.colore)}
                        >
                            {categoria.categoria_it}
                        </Button>
                    </div>
                </Aux>
                );
            });
            return categorieBlog;
        } else {
            return null;
        }
    }

    onCategorieBlogClick = (categoria, colore = null) => {
        let sezionePrecedente = this.props.sezione.sezionePrecedente;

        if (this.props.sezione.datiPagina.elencoBlog && this.props.statico.pagine) {

            let paginaObj = Object.keys(this.props.statico.pagine)
                .filter(key => this.props.statico.pagine[key]["pagina"] === 'Blog')
                .reduce((obj, key) => {
                return this.props.statico.pagine[key];
                }, {});

            // filtro elencoBlog per categoria
            let elencoBlogFiltratoPerCategoria = this.props.blogs.blog
                .filter(key => key.categoria_it === categoria)
                .map(element => {
                    return element;
                }, {});

            let datiPagina = { ...paginaObj, categoriaSelezionata: categoria, coloreCategoria: colore, elencoBlog: elencoBlogFiltratoPerCategoria }

            this.props.changePage(paginaObj.url, 'Blog', sezionePrecedente, datiPagina, null, true); // passo a pagina elenco blog filtrato
        }
    }

    onTagsBlogRender = () => {
        let Aux = props => props.children;
        if (this.props.blogs.tags) {
            let tagsBlog = this.props.blogs.tags.map(tag => {
                return (
                <Aux key={tag.id}>
                    <Badge 
                        variant="info"
                        onClick={() => this.onTagClick(tag.tag_it)}
                    >
                        {tag.tag_it}
                    </Badge>
                </Aux>
                );
            });
            return tagsBlog;
        } else {
            return null;
        }
    }

    onTagClick = tag => {
        let sezionePrecedente = this.props.sezione.sezionePrecedente;

        if (this.props.sezione.datiPagina.elencoBlog && this.props.statico.pagine) {

            let paginaObj = Object.keys(this.props.statico.pagine)
                .filter(key => this.props.statico.pagine[key]["pagina"] === 'Blog')
                .reduce((obj, key) => {
                return this.props.statico.pagine[key];
                }, {});

            // filtro elencoBlog per tags

            let elencoBlogFiltratoPerTag = this.props.blogs.blog
                .filter(key => {
                    if (key.tags_it !== null) {
                        if (key.tags_it.split(',').indexOf(tag) > -1) { return key; } else { return null; }
                    } else {
                        return null;
                    }
                })
                .map(element => {
                    return element;
                }, {});

            ////console.log(elencoBlogFiltratoPerTag);

            let datiPagina = { ...paginaObj, tagSelezionato: tag, elencoBlog: elencoBlogFiltratoPerTag }

            this.props.changePage(paginaObj.url, 'Blog', sezionePrecedente, datiPagina, null, true); // passo a pagina elenco blog filtrato
        }
    }

    onFiltroButtonClick = () => {
        this.setState({
            filtroCliccato: !this.state.filtroCliccato
        });
    }

    render() {

        let Aux = props => props.children;
        let leftColStyle = this.state.filtroCliccato ? 'toggled' : '';

        return <Aux>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 3, order: 1 }}>
                <Row noGutters className="d-flex justify-content-end">
                    <Button xs={12} variant="info" className="showFiltri mb-2 float-right" onClick={this.onFiltroButtonClick}>
                        <FontAwesomeIcon icon={["far", "filter"]} /> filtra per TAG o CATEGORIA
                    </Button>
                </Row>
                <Row noGutters>
                    <Col sm={12} className={`leftCol mb-5 ${leftColStyle}`}>
                        <Col sm={12} className="categorieBlog">
                            <h4>Categorie</h4>
                            {this.onCategorieBlogRender()}
                        </Col>
                        <Col sm={12} className="spacer15"></Col>
                        <Col sm={12} className="tags">
                            <h4>Tags</h4>
                            {this.onTagsBlogRender()}
                        </Col>
                    </Col>
                </Row>
            </Col>
        </Aux>;
    }

}

const mapStateToProps = state => {
    return { 
        router: state.router,
        sezione: state.sezione,
        statico: state.statico,
        blogs: state.blog,
    };
}

export default connect(mapStateToProps, { changePage } )(BlogFilters);

