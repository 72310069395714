import { FETCH_ALL, AUTH_LOGOUT } from 'store/actions/types';

const INITIAL_STATE = {
    cantine: null,
    tipologie: null,
    etichette: null,
    vini: null,
    formati: null,
    confezioni: null,
    prezzi: null,
    giftcollection: null,
    gifts: null
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_ALL:
            return { 
                ...state, 
                tipologie: action.payload[1],
                etichette: action.payload[2],
                cantine: action.payload[3],
                vini: action.payload[4],
                formati: action.payload[5],
                confezioni: action.payload[6],
                prezzi: action.payload[7],
                giftcollection: action.payload[9],
                gifts: action.payload[10]
            };
        case AUTH_LOGOUT:
            return {
                ...state, 
                tipologie: null,
                etichette: null,
                cantine: null,
                vini: null,
                formati: null,
                confezioni: null,
                prezzi: null,
                giftcollection: null,
                gifts: null
            }
        default:
            return state;
    }
}