export const MENU_OPEN = 'menu_open';
export const MENU_CLOSE = 'menu_close';
export const CHANGE_PAGE = 'change_page';
export const FETCH_CANTINE = 'fetch_cantine';
export const FETCH_STATIC = 'fetch_static';
export const FETCH_TIPOLOGIE = 'fetch_tipologie';
export const FETCH_ETICHETTE = 'fetch_etichette';
export const FETCH_VINI = 'fetch_vini';
export const FETCH_GIFTCOLLECTION = 'fetch_giftcollection';
export const FETCH_FORMATI = 'fetch_formati';
export const FETCH_CONFEZIONI = 'fetch_confezioni';
export const FETCH_PREZZI = 'fetch_prezzi';
export const FETCH_BLOG = 'fetch_blog';
export const FETCH_ALL = 'fetch_all';
export const CLEAR_VINO = 'clear_vino';
export const AUTH_LOGIN = 'auth_login';
export const AUTH_LOGOUT = 'auth_logout';
export const IS_AUTH = 'is_auth';
export const ERROR_LOGIN = 'error_login';
export const CHANGE_FILTER = 'change_filter';