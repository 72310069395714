import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import Logo from 'components/UI/Logo/Logo';
import Hamburger from 'components/UI/Hamburger/Hamburger';
import { changePage, changeFilter } from 'store/actions';
import 'components/UI/Header/Header.css';

class Header extends Component {
  state = {
    attivo: false
  }

  componentDidMount() {
      // attivo gli events
      this.setState({ attivo: true });
  }

    onClickLogo = () => {
      if (this.props.sezione.nomePagina !== 'homepage') {
        ////console.log('[Header] onClickLogo');
        let sezionePrecedente = this.props.sezione.sezionePagina;
        if(this.props.statico.pagine) {
          let paginaObj = Object.keys(this.props.statico.pagine)
            .filter(key => this.props.statico.pagine[key]["url"] === 'homepage')
            .reduce((obj, key) => {
              return this.props.statico.pagine[key];
            }, {});
          //////console.log(paginaObj);
          if (paginaObj) {
            this.props.changeFilter(null); // resetto i filtri
            this.props.changePage(paginaObj.url, 'Homepage', sezionePrecedente, paginaObj); // passo a pagina elenco vini cantina
          } else {
            this.props.changePage('homepage');
          }
        } else {
          this.props.changePage('homepage');
        }
      }
    }

    render() {
        return (
            <Container>
                <Row noGutters className="header align-items-center justify-content-between">
                    <div onClick={this.onClickLogo} >
                        <Logo attivo={this.state.attivo} />
                    </div>
                    <Hamburger />
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return { 
        sezione: state.sezione,
        statico: state.statico 
    };
}

export default connect(mapStateToProps, { changePage, changeFilter })(Header);