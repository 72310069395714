import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Image } from 'react-bootstrap';
import TrackVisibility from 'react-on-screen';
import BoxVino from 'components/Pagine/Cantina/ElencoVini/BoxVino/BoxVino';
import 'components/Pagine/Cantina/ElencoVini/ElencoVini.css';

class ElencoVini extends Component {
	componentDidMount() {
		////console.log('[ElencoVini] componentDidMount:: caricato');
	}

	componentDidUpdate() {
		////console.log('[ElencoVini] componentDidUpdate:: aggiornato');
	}

	componentWillUnmount() {
		////console.log('[ElencoVini] componentWillUnmount:: uscendo');
	}

	sort = (prop, arr, sortNumeric) => {
		prop = prop.split('.');
		const len = prop.length;

		arr.sort(function(a, b) {
			let i = 0;
			while (i < len) {
				a = a[prop[i]];
				b = b[prop[i]];
				i++;
			}
			if (sortNumeric === true) {
				console.log('aaaaa');
				return a - b;
			}
			if (a < b) {
				return -1;
			} else if (a > b) {
				return 1;
			} else {
				return 0;
			}
		});
		return arr;
	};

	/* sortArray = (a, b) => {
    return a - b;
  } */

	onRenderElencoVini = () => {
		// POPOLO ELENCO VINI
		let vinoObj = null;
		let filtroCantina = '';
		let filtroVino = '';
		/* 
    let filtroTipologia = '';
    let filtroFormato = '';
    let filtroConfezione = '';
 */
		if (this.props.filtri) {
			////console.log(this.props.filtri);
			if (this.props.filtri.cantina !== undefined) {
				filtroCantina = this.props.filtri.cantina.toLocaleLowerCase();
				/* ////console.log(filtroCantina); */
			}
			if (this.props.filtri.vino !== undefined) {
				filtroVino = this.props.filtri.vino.toLocaleLowerCase();
				/* ////console.log(filtroVino); */
			}
			if (this.props.filtri.tipologia !== undefined) {
				/* filtroTipologia = this.props.filtri.tipologia.toLocaleLowerCase(); */
				/* ////console.log(filtroTipologia); */
			}
			if (this.props.filtri.formato !== undefined) {
				/* filtroFormato = this.props.filtri.formato.toLocaleLowerCase(); */
				/* ////console.log(filtroFormato); */
			}
			if (this.props.filtri.confezione !== undefined) {
				/* filtroConfezione = this.props.filtri.confezione.toLocaleLowerCase(); */
				/*  ////console.log(filtroConfezione); */
			}
		}

		if (this.props.sezione.datiPagina.vini) {
			console.log('caricato elenco vini');

			let newArray = '';
			let newArraySorted = '';

			if (filtroCantina && this.props.vini) {
				vinoObj = this.props.vini;
				newArray = [...vinoObj];

				newArraySorted = this.sort('Ordine', newArray, true); // riordino per Ordine
				//newArraySorted = [...vinoObj];
			} else {
				vinoObj = this.props.sezione.datiPagina;
				newArraySorted = vinoObj['vini'];
			}

			/* vinoObj = this.props.sezione.datiPagina;
      newArraySorted = vinoObj["vini"]; */

			let counter = 0;

			return newArraySorted.map((vino, key) => {
				let nomeCantina = vino.Cantina.toLocaleLowerCase();
				let nomeVino = vino.Vino.toLocaleLowerCase();
				let controlloNomeVino = `${nomeVino} (${nomeCantina})`;

				//console.log(`${filtroVino} : ${controlloNomeVino}`);

				/* let formati = `${vino.formati}`;
        ////console.log(typeof(formati));
        let formatInt = formati.map(x => { 
          return parseInt(x, 10); 
        }); */
				/*let tipologia = `${vino[key]["Tipologia_it"]}`;
        let confezionamento = `${vino[key]["confezionamento"]}`; */

				/* ////console.log('-------------');
        ////console.log(vino);
        ////console.log(vino.formati);
        ////console.log('filtroFormato '+filtroFormato);
        ////console.log(formatInt.includes(filtroFormato));
        ////console.log('-------------'); */

				// FILTRI
				if (
					filtroCantina &&
					filtroCantina !== vino.Cantina.toLocaleLowerCase()
				) {
					return null;
				}
				if (filtroVino && filtroVino !== controlloNomeVino) {
					return null;
				}
				/* if (filtroFormato && formati.indexOf(filtroFormato) < 0) { return null } */
				/* if (filtroTipologia && filtroTipologia !== tipologia.toLocaleLowerCase()) { return null }
        
        if (filtroConfezione && filtroConfezione !== confezionamento.toLocaleLowerCase()) { return null } */

				let ComponentToTrack = ({ isVisible }) => {
					let style = {
						opacity: isVisible ? '1' : '0',
					};
					return <BoxVino vino={vino.ID} style={style} />;
				};

				const Aux = (props) => props.children;

				if (vino.SottoCantina === 'Belvento' && counter === 0) {
					console.log(counter);
					counter++;
					return (
						<Aux key={vino.SottoCantina}>
							<Row
								noGutters
								className='clearfix w-100 sottoCantina d-flex justify-content-start align-items-end mt-4 mb-5 mx-3'>
								<Image
									src={`${
										process.env.PUBLIC_URL
									}/images/cantine/loghi/belvento.png`}
									alt='Logo belvento'
									className='logoSottoCantina'
								/>
								<h3 className='ml-sm-5 ml-0 mb-0 mt-4 fsize20'>
									IL VENTO, IL MARE,
									<br />
									LA MACCHIA MEDITERRANEA
								</h3>
							</Row>
							<Col xs={6} md={4} lg={3} key={key} className='p-0'>
								<TrackVisibility partialVisibility className='boxVino'>
									<ComponentToTrack />
								</TrackVisibility>
							</Col>
						</Aux>
					);
				} else {
					return (
						<Col xs={6} md={4} lg={3} key={key} className='p-0'>
							<TrackVisibility partialVisibility className='boxVino'>
								<ComponentToTrack />
							</TrackVisibility>
						</Col>
					);
				}
			});
		}
	};

	render() {
		let elencoVini = this.props.sezione.datiPagina.vini
			? this.onRenderElencoVini()
			: '';

		return (
			<Container>
				<Row>{elencoVini}</Row>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		sezione: state.sezione,
		cantine: state.catalogo.cantine,
		tipologie: state.catalogo.tipologie,
		vini: state.catalogo.vini,
		filtri: state.filtri.selezionati,
	};
};

export default connect(mapStateToProps)(ElencoVini);
