import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import {
	fetchCantine,
	fetchStatic,
	fetchTipologie,
	fetchEtichette,
	changePage,
} from 'store/actions';
import Slideshow from 'components/UI/Slideshow/Slideshow';
import ElencoVini from 'components/Pagine/Cantina/ElencoVini/ElencoVini';
import Filtri from 'components/UI/Filtri/Filtri';
import 'components/Pagine/Cantina/DettaglioVino/DettaglioVino.css';

class DettaglioVino extends Component {
	componentDidMount() {
		////console.log('[DettaglioVino] componentDidMount');
		////console.log(this.props.router.action);

		let sezioneCorrente = this.props.sezione.sezionePagina;
		let sezionePrecedente = this.props.sezione.sezionePrecedente;

		if (this.props.cantine) {
			////console.log('[DettaglioVino] componentDidUpdate:: aggiorno pagina vino');
			let currentPath = this.props.location.pathname.split('/');
			let cantinaObj = Object.keys(this.props.cantine)
				.filter((key) => this.props.cantine[key]['url'] === currentPath[1])
				.reduce((obj, key) => {
					return this.props.cantine[key];
				}, {});
			let vinoObj = Object.keys(cantinaObj.vini)
				.filter((key) => cantinaObj.vini[key].UrlSEO === currentPath[2])
				.reduce((obj, key) => {
					return cantinaObj.vini[key];
				}, {});
			if (vinoObj) {
				// url cantina + url vino
				let url = `${cantinaObj.url}/${vinoObj.UrlSEO}`;
				// preparo oggetto per dettaglioPagina vino selezionato
				let vinoSelezionato = { ...cantinaObj, vinoSelezionato: vinoObj };
				this.props.changePage(
					url,
					sezioneCorrente,
					sezionePrecedente,
					vinoSelezionato
				); // passo a pagina dettaglio Vino
			}
		}
	}

	componentDidUpdate() {
		////console.log('[DettaglioVino] componentDidUpdate');
		////console.log(this.props.router.action);
		/*  ////console.log(this.props.router.action);
    ////console.log(this.props.router.location.pathname);
    ////console.log(this.props.sezione.nomePagina); */

		let sezioneCorrente = this.props.sezione.sezionePagina;
		if (!sezioneCorrente) sezioneCorrente = 'Cantina';
		let sezionePrecedente = this.props.sezione.sezionePrecedente;
		if (!sezionePrecedente) sezionePrecedente = 'Cantina';

		if (!this.props.sezione.nomePagina && this.props.cantine) {
			////console.log('[DettaglioVino] aggiorno pagina vino PUSH');
			let currentPath = this.props.location.pathname.split('/');
			let cantinaObj = Object.keys(this.props.cantine)
				.filter((key) => this.props.cantine[key]['url'] === currentPath[1])
				.reduce((obj, key) => {
					return this.props.cantine[key];
				}, {});
			let vinoObj = Object.keys(cantinaObj.vini)
				.filter((key) => cantinaObj.vini[key].UrlSEO === currentPath[2])
				.reduce((obj, key) => {
					return cantinaObj.vini[key];
				}, {});
			if (vinoObj) {
				// url cantina + url vino
				let url = `${cantinaObj.url}/${vinoObj.UrlSEO}`;
				// preparo oggetto per dettaglioPagina vino selezionato
				let vinoSelezionato = { ...cantinaObj, vinoSelezionato: vinoObj };
				this.props.changePage(
					url,
					sezioneCorrente,
					sezionePrecedente,
					vinoSelezionato
				); // passo a pagina dettaglio Vino
			}
		} else if (this.props.router.action === 'POP' && this.props.cantine) {
			if (
				`/${this.props.sezione.nomePagina}` !==
				this.props.router.location.pathname
			) {
				////console.log('[DettaglioVino] aggiorno pagina vino POP');
				let currentPath = this.props.router.location.pathname.split('/');
				let cantinaObj = Object.keys(this.props.cantine)
					.filter((key) => this.props.cantine[key]['url'] === currentPath[1])
					.reduce((obj, key) => {
						return this.props.cantine[key];
					}, {});
				let vinoObj = Object.keys(cantinaObj.vini)
					.filter((key) => cantinaObj.vini[key].UrlSEO === currentPath[2])
					.reduce((obj, key) => {
						return cantinaObj.vini[key];
					}, {});
				if (vinoObj) {
					// url cantina + url vino
					let url = this.props.router.location.pathname.substring(1);
					// preparo oggetto per dettaglioPagina vino selezionato
					let vinoSelezionato = { ...cantinaObj, vinoSelezionato: vinoObj };
					this.props.changePage(
						url,
						sezioneCorrente,
						sezionePrecedente,
						vinoSelezionato
					); // passo a pagina dettaglio Vino
				}
			}
		}
	}

	onFiltriPer = () => {
		////console.log('onFiltriPer');
		////console.log(this.props.filtri);

		if (!this.props.filtri) {
			return null;
		}

		if (
			this.props.filtri.cantina !== '' ||
			this.props.filtri.vino !== '' ||
			this.props.filtri.tipologia !== '' ||
			this.props.filtri.formato !== '' ||
			this.props.filtri.confezione !== ''
		) {
			let elencoFiltriSelezionati = '';
			if (this.props.filtri.cantina)
				elencoFiltriSelezionati += this.props.filtri.cantina;
			if (this.props.filtri.vino)
				elencoFiltriSelezionati += ` - ${this.props.filtri.vino}`;
			if (this.props.filtri.tipologia)
				elencoFiltriSelezionati += ` - ${this.props.filtri.tipologia}`;
			if (this.props.filtri.formato)
				elencoFiltriSelezionati += ` - ${this.props.filtri.formato} ml`;
			if (this.props.filtri.confezione)
				elencoFiltriSelezionati += ` - ${this.props.filtri.confezione}`;

			return (
				<Container className='filtri'>
					<Row noGutters>
						Filtrando per: <b>{elencoFiltriSelezionati}</b>
					</Row>
				</Container>
			);
		} else {
			return null;
		}
	};

	render() {
		const Aux = (props) => props.children;

		let paginaCorrente = this.props.location.pathname.split('/');

		return (
			<Aux>
				<Slideshow
					sezioneSlide='dettaglioVino'
					paginaSlide={paginaCorrente[0]}
				/>
				<Container>
					<Row noGutters>
						<Col sm={12}>
							<Filtri />
						</Col>
						<Col sm={12} className='filtrandoPer'>
							{this.onFiltriPer()}
						</Col>
						<Col sm={12}>
							<ElencoVini />
						</Col>
					</Row>
				</Container>
			</Aux>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		router: state.router,
		sezione: state.sezione,
		statico: state.statico,
		cantine: state.catalogo.cantine,
		tipologie: state.catalogo.tipologie,
		etichette: state.catalogo.etichette,
		filtri: state.filtri.selezionati,
	};
};

export default connect(
	mapStateToProps,
	{ fetchCantine, fetchStatic, fetchTipologie, fetchEtichette, changePage }
)(DettaglioVino);
