import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slideshow from 'components/UI/Slideshow/Slideshow';
import Filtri from 'components/UI/Filtri/Filtri';
import { changePage, fetchStatic, fetchCantine, fetchPrezzi, fetchTipologie, fetchEtichette } from 'store/actions';
import 'components/Pagine/Prezzi/Prezzi.css';

class Prezzi extends Component {
  state = {
    caricandoPrezzi: null
  }

  componentDidMount() {
    ////console.log('[Prezzi] componentDidMount');
    ////console.log(this.props.router.action);

    if (this.props.prezzi) {
      let paginaObj = Object.keys(this.props.statico.pagine)
            .filter(key => this.props.statico.pagine[key]["pagina"] === 'Prezzi')
            .reduce((obj, key) => {
              return this.props.statico.pagine[key];
            }, {});
      //////console.log(paginaObj);
      if (paginaObj) {
        this.props.changePage(paginaObj.url, 'Prezzi', 'Prezzi', paginaObj);
      }
    }
  }

  componentDidUpdate() {
    ////console.log('[Prezzi] componentDidUpdate');
    ////console.log(this.props.router.action);

    ////console.log('nomePagina: '+this.props.sezione.nomePagina);
    if (!this.props.sezione.nomePagina && this.props.statico.pagine) {
      ////console.log('[Prezzi] componentDidUpdate:: recuperato contenuto static aggiorno Prezzi');
      let paginaObj = Object.keys(this.props.statico.pagine)
            .filter(key => this.props.statico.pagine[key]["pagina"] === 'Prezzi')
            .reduce((obj, key) => {
              return this.props.statico.pagine[key];
            }, {});
      //////console.log(paginaObj);
      if (paginaObj) {
        this.props.changePage(paginaObj.url, 'Prezzi', 'Prezzi', paginaObj);
      }
    } else if(this.props.router.action === 'POP' && this.props.statico.pagine) {
      ////console.log('tornando alla pagina prezzi!');
    }
  }

  sort = (prop, arr) => {
    prop = prop.split('.');
    const len = prop.length;

    arr.sort(function (a, b) {
        let i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    });
    return arr;
  };

  onClickPrezzo = (urlCantina, urlTarget, idprezzo, gift = null) => {
    if (urlCantina && urlTarget && idprezzo) {
      let cantina = null;

      /* ////console.log(urlCantina);
      ////console.log(urlTarget); */

      if (gift !== null) {

        // LINK A SCHEDA GIFT
        if (this.props.giftcollection) {
          // ciclo la cantina
          let giftcollectionObj = this.props.giftcollection;
          cantina = Object.keys(giftcollectionObj)
            .filter(key => giftcollectionObj[key].url === urlCantina)
            .reduce((obj, key2) => {
              //////console.log(key2);
              return giftcollectionObj[key2];
            }, {});
        }
        //////console.log(cantina);
        if (cantina) {
          let giftObj = Object.keys(cantina.gifts)
              .filter(key => cantina.gifts[key].UrlSEO === urlTarget)
              .reduce((obj, key2) => {
                return cantina.gifts[key2];
              }, {});
          if (giftObj) {
            // url cantina + url gift
            //////console.log(giftObj);
            let url = `giftcollection/${urlCantina}/${urlTarget}`;
            // preparo oggetto per dettaglioPagina vino selezionato
            let cantinaObj = cantina;
            let giftSelezionato = {...cantinaObj, giftSelezionato: giftObj}
            this.props.changePage(url, 'GiftCollection', 'Prezzi', giftSelezionato, idprezzo); // passo a pagina dettaglio Vino
          }
        }

      } else {

        // LINK A SCHEDA VINO NORMALE
        if (this.props.cantine) {
          // ciclo la cantina
          let cantinaObj = this.props.cantine;
          cantina = Object.keys(cantinaObj)
            .filter(key => cantinaObj[key].url === urlCantina)
            .reduce((obj, key2) => {
              //////console.log(key2);
              return cantinaObj[key2];
            }, {});
        }
        //////console.log(cantina);
        if (cantina) {
          let vinoObj = Object.keys(cantina.vini)
              .filter(key => cantina.vini[key].UrlSEO === urlTarget)
              .reduce((obj, key2) => {
                return cantina.vini[key2];
              }, {});
          if (vinoObj) {
            // url cantina + url vino
            //////console.log(vinoObj);
            let url = `${urlCantina}/${urlTarget}`;
            // preparo oggetto per dettaglioPagina vino selezionato
            let cantinaObj = cantina;
            let vinoSelezionato = {...cantinaObj, vinoSelezionato: vinoObj}
            this.props.changePage(url, 'Cantina', 'Prezzi', vinoSelezionato, idprezzo); // passo a pagina dettaglio Vino
          }
        }

      }


      
    }
  }

  onRenderPrezzi = () => {
    let Aux = props => props.children;

    // tabella prezzi
      let prezziObj = this.props.prezzi;
      let filtroCantina = '';
      let filtroVino = '';
      let filtroTipologia = '';
      let filtroFormato = '';
      let filtroConfezione = '';
      if(this.props.filtri) {
        ////console.log(this.props.filtri);
        if(this.props.filtri.cantina !== undefined) {
          filtroCantina = this.props.filtri.cantina.toLocaleLowerCase();
          /* ////console.log(filtroCantina); */
        }
        if(this.props.filtri.vino !== undefined) {
          filtroVino = this.props.filtri.vino;
          /* ////console.log(filtroVino); */
        }
        if(this.props.filtri.tipologia !== undefined) {
          filtroTipologia = this.props.filtri.tipologia.toLocaleLowerCase();
         /*  ////console.log(filtroTipologia); */
        }
        if(this.props.filtri.formato !== undefined) {
          filtroFormato = this.props.filtri.formato.toLocaleLowerCase();
         /*  ////console.log(filtroFormato); */
        }
        if(this.props.filtri.confezione !== undefined) {
          filtroConfezione = this.props.filtri.confezione.toLocaleLowerCase();
          /* ////console.log(filtroConfezione); */
        }
      }

      let newArray = [...prezziObj];
      let newArraySorted = '';

      //console.log(prezziObj);

      if (filtroCantina) {
        newArraySorted = this.sort('Vino', newArray);
      } else {
        newArraySorted = prezziObj;
      }

      //console.log(newArraySorted);

        return Object.keys(newArraySorted).map((key, counter) => {
            // recupero i prezzi di bellavista (default)
              
            let idPrezzo = `${newArraySorted[key]["id"]}`;
            let vino = `${newArraySorted[key]["Vino"]}`;
            let vinoUrl = `${newArraySorted[key]["VinoUrl"]}`;
            let cantina = `${newArraySorted[key]["Cantina"]}`;
            let cantinaUrl = `${newArraySorted[key]["CantinaUrl"]}`;
            //let urlFinale = `${cantinaUrl}/${vinoUrl}`;
            let controlloNomeVino = `${vino} (${cantina})`;
            let annata = `${newArraySorted[key]["annata"]}`;
            let formato = `${newArraySorted[key]["formato"]}`;
            let formato_desc = `${newArraySorted[key]["formato_desc"]}`;
            let confezionamento = `${newArraySorted[key]["confezionamento"]}`;
            let tipologia = `${newArraySorted[key]["Tipologia_it"]}`;
            let disponibilita = `${newArraySorted[key]["disponibilita"]}`;
            let icona = `${newArraySorted[key]["icona"]}`;
            let prezzoFix = '';
            if(newArraySorted[key]["prezzo"]) {
              prezzoFix = newArraySorted[key]["prezzo"].replace('.',',');
            }
            let prezzo = `€${prezzoFix}`;

            //console.log(`${filtroVino} : ${controlloNomeVino}`);

            // FILTRI
            if (filtroCantina && filtroCantina !== cantina.toLocaleLowerCase()) { return null }
            /* if (filtroVino && filtroVino !== urlFinale.toLocaleLowerCase()) { return null } */
            if (filtroVino && filtroVino !== controlloNomeVino) { return null }
            if (filtroTipologia && filtroTipologia !== tipologia.toLocaleLowerCase()) { return null }
            if (filtroFormato && filtroFormato !== formato.toLocaleLowerCase()) { return null }
            if (filtroConfezione && filtroConfezione !== confezionamento.toLocaleLowerCase()) { return null }

            let rowDisp = '';
            if (disponibilita !== '') {
              rowDisp = <Row noGutters className="showMobile dispo mb-3">
                <Col className="d-flex justify-content-end align-items-center pb-1 disponibilitaMobile">
                  <span>{disponibilita}</span>
                </Col>
              </Row>;
            }

            let iconaImg = '';
            if(icona !== "null") {
              iconaImg = <FontAwesomeIcon icon={["far", "gift"]} className="giftIcon mx-3" />
            }

            if(idPrezzo !== 'undefined') {
              return (
                <Aux key={idPrezzo}>
                  <Row noGutters className="rowPrezzi listino mt-1">
                    <Col className="d-flex justify-content-start align-items-center nomeCantina pl-2">
                      {cantina}
                    </Col>
                    <Col className="d-flex justify-content-start align-items-center nomeVino">
                      {vino}
                      {iconaImg}
                    </Col>
                    <Col className="d-flex justify-content-start align-items-center annata">
                      {annata}
                    </Col>
                    <Col className="d-flex justify-content-start align-items-center formatoDesc">
                      {formato_desc}
                    </Col>
                    <Col className="d-flex justify-content-start align-items-center">
                      {confezionamento}
                    </Col>
                    <Col className="d-flex flex-column flex-md-row justify-content-between align-items-center prezzi">
                      {prezzo}
                      <FontAwesomeIcon icon={["fal", "search"]} className="fsize20 vaiProdotto" onClick={() => icona === "null" ? this.onClickPrezzo(cantinaUrl, vinoUrl, idPrezzo) : this.onClickPrezzo(cantinaUrl, vinoUrl, idPrezzo, "gift") } />
                    </Col>
                    <Col className="d-flex flex-column flex-sm-row justify-content-between justify-content-md-center align-items-center disponibilita text-center">
                      {disponibilita}  
                    </Col> 
                  </Row>
                  {rowDisp}
                </Aux>
              );
            } else {
              return null;
            }
          });

  }

  onFiltriPer = () => {

    ////console.log('onFiltriPer');
    ////console.log(this.props.filtri);

    if(!this.props.filtri) {
      return null;
    }

    if (this.props.filtri.cantina !== '' || this.props.filtri.vino !== '' || this.props.filtri.tipologia !== '' || this.props.filtri.formato !== '' || this.props.filtri.confezione !== '') {

      let elencoFiltriSelezionati = '';
      if (this.props.filtri.cantina) elencoFiltriSelezionati += this.props.filtri.cantina;
      if (this.props.filtri.vino) elencoFiltriSelezionati += ` - ${this.props.filtri.vino}`;
      if (this.props.filtri.tipologia) elencoFiltriSelezionati += ` - ${this.props.filtri.tipologia}`;
      if (this.props.filtri.formato) elencoFiltriSelezionati += ` - ${this.props.filtri.formato} ml`;
      if (this.props.filtri.confezione) elencoFiltriSelezionati += ` - ${this.props.filtri.confezione}`;

      return (
        <Container className="filtri">
          <Row noGutters>Filtrando per: <b>{elencoFiltriSelezionati}</b></Row>
        </Container>
      );
    } else {
      return null;
    }
  }

  render() {

    let Aux = props => props.children;

    let elencoPrezzi = this.props.prezzi ? this.onRenderPrezzi() : '';

    let filtriAttivi = null;

    if(this.props.filtri.cantina || this.props.filtri.vino || this.props.filtri.tipologia || this.props.filtri.formato) {
      filtriAttivi = <Col sm={12} className="filtrandoPer">
        {this.onFiltriPer()}
      </Col>
    }

    return (
      <Aux>
        <Slideshow sezioneSlide='prezzi' paginaSlide='prezzi' />
        <Container>
          <Row noGutters>
            <Col sm={12}>
              <Filtri />
            </Col>
            {filtriAttivi}
            <Col sm={12} id="elencoPrezzi">
              <Row noGutters className="rowPrezzi thead">
                <Col className="titoloPrezzo fsemib d-flex justify-content-start align-items-center nomeCantina pl-2">
                  Cantina
                </Col>
                <Col className="titoloPrezzo fsemib d-flex justify-content-start align-items-center nomeVino">
                  Prodotto
                </Col>
                <Col className="titoloPrezzo fsemib d-flex justify-content-start align-items-center annata">
                  Anno
                </Col>
                <Col className="titoloPrezzo fsemib d-flex justify-content-start align-items-center formatoDesc">
                  Formato
                </Col>
                <Col className="titoloPrezzo fsemib d-flex justify-content-start align-items-center">
                  Conf.
                </Col>
                <Col className="titoloPrezzo fsemib d-flex justify-content-start align-items-center">
                  Prezzo
                </Col>
                <Col className="titoloPrezzo disponibilita fsemib d-flex justify-content-around align-items-center">
                  Disponibilità  
                </Col> 
              </Row>
              {elencoPrezzi}
            </Col>
          </Row>
        </Container>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return { 
    router: state.router,
    sezione: state.sezione,
    cantine: state.catalogo.cantine,
    tipologie: state.catalogo.tipologie,
    etichette: state.catalogo.etichette,
    prezzi: state.catalogo.prezzi,
    statico: state.statico,
    filtri: state.filtri.selezionati,
    giftcollection: state.catalogo.giftcollection
  };
}

export default connect(mapStateToProps, { changePage, fetchStatic, fetchCantine, fetchPrezzi, fetchTipologie, fetchEtichette })(Prezzi);