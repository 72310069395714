import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import md5 from 'md5';
import { authLogin, fetchStatic, fetchCantine, fetchTipologie, fetchEtichette } from 'store/actions';
import Logo from 'components/UI/Logo/Logo';
import 'components/Pagine/Login/Login.css';

const FieldInput = ({ input, meta, type, placeholder, min, max }) => {
    return (
        <Form.Control
            type={type}
            placeholder={placeholder}
            min={min}
            max={max}
            value={input.value}
            autoComplete='new-password'
            onChange={input.onChange} />
    )
}

class Login extends Component {
    state = {
        validated: false,
        username: '',
        password: '',
        attivo: false,
        showError: false,
        showPassword: false,
        updatingApi: false,
        messageError: ''
    }

    componentDidMount() {

        // chiamate api
        ////console.log('[Login] componentDidMount');

    }

    componentDidUpdate() {
        // error handling risposta da api/login/
        if (this.props.auth.login !== null) {
            if (this.props.auth.login.error && !this.state.showError) {
                this.setState({ 
                    showError: true, 
                    messageError: this.props.auth.login.errorMsg
                });
            }
        }

        if (!this.state.attivo) {
            // attivo gli events
            this.setState({ attivo: true });
        }
    }

    onSubmit = formProps => {
        ////console.log(formProps);
        // se Username e Password sono compilati invio action creator authLogin
        if (formProps.Username && formProps.Password) {
            const Username = formProps.Username.toLowerCase();
            const Password = md5(formProps.Password);
            const authPw = `${Username}${Password}`;
            this.props.authLogin(authPw, Username);
        } else {

            if (!formProps.Username && !formProps.Password) {
                this.setState({ 
                    showError: true, 
                    messageError: 'Inserire username e password' 
                });
            } else if (!formProps.Password && formProps.Username) {
                this.setState({ 
                    showError: true, 
                    messageError: 'Inserire password' 
                });
            } else {
                this.setState({ 
                    showError: true, 
                    messageError: 'Inserire username' 
                });
            }
            
        }
        
    }

    onShowPassword = () => {
        ////console.log('show password');
        this.setState({
            showPassword: true
        })
    }

    onHidePassword = () => {
        ////console.log('hide password');
        this.setState({
            showPassword: false
        })
    }

    onRenderError = () => {
        if (this.state.showError) {
            return <Alert variant="danger">
                {this.state.messageError}
            </Alert>
        } else {
            return null;
        }
    }

    render() {

        const { validated } = this.state.validated;
        const { handleSubmit } = this.props;

        // mostra nascondi password
        let passwordShow = '';
        let passwordHide = '';
        let typeT = '';
        if (this.state.showPassword) {
            passwordShow = 'selected';
            passwordHide = 'selected';
            typeT = 'text';
        } else {
            passwordShow = '';
            passwordHide = '';
            typeT = 'password';
        };

        return (
            <div>
                <Container className="loginAuth">
                    <Row noGutters className="d-flex flex-column justify-content-center">
                        <Logo attivo={this.state.attivo} />
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit(this.onSubmit)}
                            className="boxLogin"
                        >
                        <input type="hidden" value="something" />
                            <Form.Row className="d-flex justify-content-between p-4">
                                <Col xs={{ span: 12, offset: 0 }} sm={{ span: 6, offset: 3 }}>
                                    <label className="titleLogin fsize25">Login</label>

                                    <Form.Group controlId="formBasicUsername" className="usernameInput d-flex flex-column justify-content-start">
                                        <Form.Label>Username</Form.Label>
                                        <Field type="text" name="Username" component={FieldInput} defaultValue='' required placeholder="inserisci username" />
                                        <Form.Text className="text-muted">
                                            L'username deve rispettare maiuscole e minuscole
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword" className="passwordInput d-flex flex-column justify-content-start">
                                        <Form.Label>Password</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Append>
                                                <FontAwesomeIcon icon={["far", "eye"]} onClick={this.onShowPassword} className={`${passwordShow} fsize25 eyePasswordShow`} />
                                                <FontAwesomeIcon icon={["far", "eye-slash"]} onClick={this.onHidePassword} className={`${passwordHide} fsize25 eyePasswordHide`} />
                                            </InputGroup.Append>
                                            <Field type={`${typeT}`} name="Password" component={FieldInput} defaultValue='' required placeholder="inserisci password" />
                                        </InputGroup>
                                        <Form.Text className="text-muted">
                                            La password deve rispettare maiuscole e minuscole
                                        </Form.Text>
                                    </Form.Group>
                                    {this.onRenderError()}
                                    <Button variant="secondary" type="submit">
                                        Invia
                                    </Button>
                                </Col>
                            </Form.Row>
                        </Form>
                    </Row>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { 
        auth: state.auth,
        statico: state.statico,
        sezione: state.sezione,
        cantine: state.catalogo.cantine,
        tipologie: state.catalogo.tipologie,
        etichette: state.catalogo.etichette
    };
}

Login = connect(
    mapStateToProps,
    { authLogin, fetchStatic, fetchCantine, fetchTipologie, fetchEtichette }
)(Login);

export default reduxForm({
    form: 'loginForm' // a unique name for this form
})(Login);
