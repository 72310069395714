import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Col, Form, Button } from 'react-bootstrap';
import { reduxForm, Field, change } from 'redux-form';
import {
	changePage,
	fetchVini,
	fetchFormati,
	fetchConfezioni,
	changeFilter,
	reinitializeReduxForm,
} from 'store/actions';
import 'components/UI/Filtri/Filtri.css';

/* const ReduxFormControl = ({input, label, values, defaultValue}) => {
  ////console.log('defaultvalue: '+defaultValue);
  return (
  <select className="form-control" defaultValue={defaultValue} onChange={input.onChange} onBlur={() => input.onBlur(input.value)} {...input}>
    <option value="">-- {label} --</option>
    {values}
  </select>
  );
} */

/* const renderSelect = props => {
  console.log(props);
  return (
      <Form.Control 
          as="select" 
          name="cantina"
          value={props.input.value}
          onChange={props.input.onChange}
          onBlur={() => props.input.onBlur(props.input.value)}
          defaultValue={props.input.defaultValue}
      >
        <option>-- {props.label} --</option>
        {props.options}
      </Form.Control>
  );
} */

class Filtri extends Component {
	state = {
		validated: false,
	};

	componentDidMount() {
		console.log('[Filtri] componentDidMount');
		console.log(this.props.initialValues);
		// re-inizializzo REDUX-FORM
		this.props.reinitializeReduxForm(this.props.initialValues);
	}

	componentDidUpdate() {
		console.log('[Filtri] componentDidUpdate');
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.value !== this.props.value;
	}

	componentWillReceiveProps(nextProps) {
		//console.log(nextProps);
		if (
			nextProps.dirty &&
			nextProps.valid &&
			nextProps.values !== this.props.values
		) {
			console.log(nextProps.values);
			//this.onFormFieldChange(nextProps.values);
		}
	}

	componentWillUnmount() {}

	sort = (prop, arr) => {
		prop = prop.split('.');
		const len = prop.length;

		arr.sort(function(a, b) {
			let i = 0;
			while (i < len) {
				a = a[prop[i]];
				b = b[prop[i]];
				i++;
			}
			if (a < b) {
				return -1;
			} else if (a > b) {
				return 1;
			} else {
				return 0;
			}
		});
		return arr;
	};

	onRenderCantine = () => {
		let cantineList = null;
		if (this.props.cantine) {
			const newArray = [...this.props.cantine];
			const newArrayCantine = this.sort('cantina', newArray);
			cantineList = newArrayCantine.map((value) => {
				let nomecantina = '';
				if (value.nomealternativo) {
					nomecantina = value.nomealternativo;
				} else {
					nomecantina = value.cantina;
				}
				return (
					<option key={value.id} value={value.cantina}>
						{nomecantina}
					</option>
				);
			});
		}
		return cantineList;
	};

	onRenderTipologie = () => {
		let tipologieList = null;
		if (this.props.tipologie) {
			const newArray = [...this.props.tipologie];
			const newArrayTipologie = this.sort('tipologia_it', newArray);
			tipologieList = newArrayTipologie.map((value) => {
				return (
					<option key={value.id} value={value.tipologia}>
						{value.tipologia_it}
					</option>
				);
			});
		}
		return tipologieList;
	};

	onRenderVini = () => {
		let viniList = null;
		console.log('onRenderVini');
		if (this.props.vini) {
			const newArray = [...this.props.vini];
			const newArrayVini = this.sort('Vino', newArray);
			// filtrati per cantina?
			let cantinaSelezionata = '';
			if (this.props.sezione.datiPagina.cantina) {
				cantinaSelezionata = this.props.sezione.datiPagina.cantina;
			} else if (this.props.initialValues.cantina) {
				cantinaSelezionata = this.props.initialValues.cantina;
			}

			console.log('cantina: ' + cantinaSelezionata);
			if (cantinaSelezionata !== '') {
				console.log('vini filtrati per: ' + cantinaSelezionata);
				viniList = newArray.map((value) => {
					let vinoNome = `${value.Vino} (${value.Cantina})`;
					//console.log(value.Cantina +' : '+cantina);
					if (cantinaSelezionata !== value.Cantina) {
						return null;
					}
					return (
						<option key={value.ID} value={vinoNome}>
							{vinoNome}
						</option>
					);
				});
			} else {
				console.log('tutti i vini');
				viniList = newArrayVini.map((value) => {
					let vinoNome = `${value.Vino} (${value.Cantina})`;
					return (
						<option key={value.ID} value={vinoNome}>
							{vinoNome}
						</option>
					);
				});
			}
		}
		return viniList;
	};

	onRenderFormati = () => {
		let formatiList = null;
		if (this.props.formati) {
			const newArray = [...this.props.formati];
			const newArrayFormati = this.sort('id', newArray);
			formatiList = newArrayFormati.map((value) => {
				return (
					<option key={value.id} value={value.formato}>
						{value.formato} ml
					</option>
				);
			});
		}
		return formatiList;
	};

	onRenderConfezioni = () => {
		let confezioniList = null;
		if (this.props.confezioni) {
			const newArray = [...this.props.confezioni];
			const newArrayConfezioni = this.sort('confezione', newArray);
			confezioniList = newArrayConfezioni.map((value) => {
				return (
					<option key={value.id} value={value.confezione}>
						{value.confezione}
					</option>
				);
			});
		}
		return confezioniList;
	};

	onSubmit = (formProps) => {
		console.log(formProps);
		// cambio cantina?
		if (formProps.cantina !== this.props.initialValues.cantina) {
			formProps = { ...formProps, vino: '' };
		}
		this.props.changeFilter(formProps);
	};

	onClearChanges = () => {
		const { reset } = this.props;
		reset();
		this.props.changeFilter(reset);
	};

	onChangeCantina = (formProps) => {
		console.log(formProps);
		/* this.props.change('filtriForm', filtroName, filtroValue) */
	};

	render() {
		let Aux = (props) => props.children;

		const { validated } = this.state.validated;
		const { handleSubmit } = this.props;

		// filtri oscurati tranne le cantine (oscurata 18/03/2022)

		return (
			<Aux>
				<Container className='filtri'>
					<Form
						noValidate
						validated={validated}
						onSubmit={handleSubmit(this.onSubmit)}
						className='boxFiltri'
						name='filtriForm'>
						<Form.Row className='d-flex justify-content-between p-4'>
							<Col sm={6} lg={3}>
								<Form.Group>
									<Field
										name='cantina'
										id='selectCantina'
										component='select'
										className='form-control'>
										<option value=''>-- cantine --</option>
										{this.onRenderCantine()}
									</Field>
								</Form.Group>
							</Col>
							{/* <Col sm={6} lg={2}>
                <Form.Group>
                  <Field name="vino" id="selectVino" component="select" className="form-control">
                    <option value="">-- prodotti --</option>
                    {this.onRenderVini()}
                  </Field>
                </Form.Group>
              </Col>
              <Col sm={6} lg={2}>
                <Form.Group>
                  <Field name="tipologia" id="selectTipologia" component="select" className="form-control">
                    <option value="">-- tipologie --</option>
                    {this.onRenderTipologie()}
                  </Field>
                </Form.Group>
              </Col>
              <Col sm={6} lg={2}>
                <Form.Group>
                  <Field name="formato" id="selectFormato" component="select" className="form-control">
                    <option value="">-- formati --</option>
                    {this.onRenderFormati()}
                  </Field>
                </Form.Group>
              </Col>
              <Col sm={6} lg={2}>
                <Form.Group>
                  <Field name="confezione" id="selectConfezione" component="select" className="form-control">
                    <option value="">-- confezioni --</option>
                    {this.onRenderConfezioni()}
                  </Field>
                </Form.Group>
              </Col> */}
							<Col sm={6} lg={2}>
								<Button variant='success' type='submit' className='inviaFiltro'>
									Filtra
								</Button>
								<Button
									variant='secondary'
									type='reset'
									className='resettaFiltro'
									onClick={this.onClearChanges}>
									Reset
								</Button>
							</Col>
						</Form.Row>
					</Form>
				</Container>
			</Aux>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		sezione: state.sezione,
		cantine: state.catalogo.cantine,
		tipologie: state.catalogo.tipologie,
		vini: state.catalogo.vini,
		formati: state.catalogo.formati,
		confezioni: state.catalogo.confezioni,
		initialValues: state.filtri.selezionati,
	};
};

Filtri = reduxForm({
	form: 'filtriForm', // a unique name for this form,
	enableReinitialize: true,
	onChange: (values, dispatch, props, previousValues) => {
		console.log(values);
		console.log(dispatch);
		console.log(props);
		console.log(previousValues);
		/* props.change('coapo'); */
		//dispatch();
	},
})(Filtri);

Filtri = connect(
	mapStateToProps,
	{
		changePage,
		fetchVini,
		fetchFormati,
		fetchConfezioni,
		changeFilter,
		reinitializeReduxForm,
		change,
	}
)(Filtri);

export default Filtri;
