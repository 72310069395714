import { CHANGE_FILTER, CHANGE_PAGE } from 'store/actions/types';

const INITIAL_STATE = {
    selezionati: {
      cantina: '',
      vino: '',
      tipologia: '',
      formato: '',
      confezione: ''
    }
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CHANGE_FILTER:
            return { ...state, selezionati: action.payload };
        case CHANGE_PAGE:
            // PREPARO FILTRI SELEZIONATI INIZIALI
            let cantina = '';
            if (action.payload.datiPagina.cantina) {
                cantina = action.payload.datiPagina.cantina;
            }
            const filtri = {
                cantina: cantina
            }
            return { ...state, selezionati: filtri };
        default:
            return state;
    }
}