import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import TrackVisibility from 'react-on-screen';
import BoxGift from 'components/Pagine/GiftCollection/ElencoGifts/BoxGift/BoxGift';
import 'components/Pagine/GiftCollection/ElencoGifts/ElencoGifts.css';

class ElencoGifts extends Component {
  componentDidMount() {
    ////console.log('[ElencoGifts] componentDidMount:: caricato');
  }

  componentDidUpdate() {
    ////console.log('[ElencoGifts] componentDidUpdate:: aggiornato');
  }

  componentWillUnmount() {
    ////console.log('[ElencoGifts] componentWillUnmount:: uscendo');
  }

  sort = (prop, arr) => {
    prop = prop.split('.');
    const len = prop.length;

    arr.sort(function (a, b) {
        let i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    });
    return arr;
  };

  onRenderElencoGifts = () => {
    // POPOLO ELENCO GIFTS
    let giftsObj = null;

    if(this.props.sezione.datiPagina.gifts) {
      ////console.log('caricato elenco gifts');
      let newArraySorted = '';

      giftsObj = this.props.sezione.datiPagina;
      //console.log(giftsObj["gifts"]);
      newArraySorted = giftsObj["gifts"];
      
      return newArraySorted.map((gift, key) => {

        let ComponentToTrack = ({ isVisible }) => {
            let style = {
                opacity: isVisible ? '1' : '0'
            };
            return <BoxGift gift={gift.ID} style={style} />;
        }

        return <Col xs={12} sm={6} lg={4} key={key} className="p-0">
            <TrackVisibility partialVisibility className="boxGift">
              <ComponentToTrack />
            </TrackVisibility>
          </Col>
      });
    }
  }

  render() {

    let elencoGifts = this.props.sezione.datiPagina.gifts ? this.onRenderElencoGifts() : '';

    return (
      <Container>
        <Row>{elencoGifts}</Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    sezione: state.sezione,
    cantine: state.catalogo.cantine,
    tipologie: state.catalogo.tipologie,
    vini: state.catalogo.vini,
    filtri: state.filtri.selezionati 
  }
}

export default connect(mapStateToProps)(ElencoGifts);