import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePage } from 'store/actions';

export default function withPermissions(OriginalComponent) {
    
    class Permitted extends Component {

        componentDidMount() {
            /* this._checkAndRedirect(); */
            //console.log('sono in componentDidMount withPermissions');
        }

        componentDidUpdate() {
            /* this._checkAndRedirect(); */
            //console.log('sono in componentDidUpdate withPermissions');
        }

        _checkAndRedirect() {

            /* console.log(this.props.router);
            console.log(this.props.auth);

            console.log(this.props.auth.permessicantine); */

            // controllo se ha il permesso per accedere alla pagina cantina
            if (this.props.auth.permessicantine) {
                let percorsodestinazione = this.props.router.location.pathname.split('/')[1];
                //console.log(percorsodestinazione);
                // fix giftcollection
                if (percorsodestinazione === 'giftcollection') {
                    percorsodestinazione = this.props.router.location.pathname.split('/')[2];
                }
                //console.log(percorsodestinazione);
                let checkUrl = this.props.auth.permessicantine.filter(key => key === percorsodestinazione);
                //console.log(checkUrl);
                if (checkUrl.length > 0) {
                    return true;
                } else {
                    //console.log('accesso negato!');
                    let paginaObj = Object.keys(this.props.statico.pagine)
                        .filter(key => this.props.statico.pagine[key]["pagina"] === 'Forbidden')
                        .reduce((obj, key) => {
                        return this.props.statico.pagine[key];
                        }, {});
                    //////console.log(paginaObj);
                    if (paginaObj) {
                        let datiPagina = { ...paginaObj }
                        this.props.changePage(paginaObj.url, 'Forbidden', 'Forbidden', datiPagina);
                    }
                }
            }
        }
        
        render() {

            let Aux = props => props.children;

            //return original component with additional props
            return <Aux>
                { this._checkAndRedirect() ? <OriginalComponent {...this.props} /> : null }
            </Aux>
        }
    }

    const mapStateToProps = state => {
        return { 
            router: state.router,
            sezione: state.sezione,
            statico: state.statico,
            auth: state.auth.login
        };
    }
    
    return connect(mapStateToProps, { changePage })(Permitted);
}